export function capitalizeFirstLetter(word) {
  if (word === undefined || word === null) return undefined;
  return String(word).charAt(0).toUpperCase() + String(word).slice(1);
}

export function convertDateTimeToDate(dateTime) {
  // Create a new Date object using the DateTime
  const date = new Date(dateTime);

  // Extract individual components of the date
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Months are zero-indexed
  const day = date.getDate();

  // Format the date as a string
  const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""}${day}`;

 
  return formattedDate;
}

export function getOffSetValue() {
  // Get the timezone offset in minutes
  const offsetInMinutes = new Date().getTimezoneOffset();
  // Determine the sign of the offset (e.g., 330 or -330)
  const offsetSign = offsetInMinutes >= 0 ? "-" : "";
  // Get offset value
  const offsetValue = `${offsetSign}${Math.abs(offsetInMinutes)}`;

  return offsetValue;
}

export function convertDateTimeToGMT(dateTime) {
  // Convert the selected date to GMT
  const gmtDate = new Date(dateTime.toLocaleString("en-US", { timeZone: "GMT" }));

  // Format the date in a string suitable for your API
  const formattedDate = gmtDate.toISOString();

  // Now, you can send the formattedDate to your API
  return formattedDate;
}

export function changeDateTimeFormat(datetimeValue) {
  // Create a new Date object with the datetime value
  var dateObject = new Date(datetimeValue);

  // Extract date, hour, and second components
  var year = dateObject.getFullYear();
  var month = dateObject.getMonth() + 1; // Months are zero-based, so add 1
  var day = dateObject.getDate();
  var hour = dateObject.getHours();
  var minute = dateObject.getMinutes();
  // var second = dateObject.getSeconds();

  // Format the components as a string
  var formattedDate =
    year + "-" + addZero(month) + "-" + addZero(day) + "T" + addZero(hour) + ":" + addZero(minute);

  function addZero(value) {
    // Helper function to add leading zero if needed
    return value < 10 ? "0" + value : value;
  }

  return formattedDate;
}
// Function to convert date to the format year/month/day
export function formatDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${month}/${day}/${year}`;
};

export function formatDateWithTime(dateString) {
  const date = new Date(dateString);
  
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; // Convert to 12-hour format, with 12 instead of 0

  // Format with date and time including AM/PM
  return `${month}/${day}/${year} ${hours}:${minutes}:${seconds} ${ampm}`;
}


export function changeDateFormat(datetimeValue) {
  // Create a new Date object with the datetime value
  var dateObject = new Date(datetimeValue);

  // Extract date, hour, and second components
  var year = dateObject.getFullYear();
  var month = dateObject.getMonth() + 1; // Months are zero-based, so add 1
  var day = dateObject.getDate();

  // Format the components as a string
  var formattedDate = year + "-" + addZero(month) + "-" + addZero(day);

  function addZero(value) {
    // Helper function to add leading zero if needed
    return value < 10 ? "0" + value : value;
  }

  return formattedDate;
}
