import React, { useState, useEffect } from "react";
import { useSoftUIController, setMiniSidenav } from "context/softuiContext";

// Soft UI Dashboard PRO React example components
import Sidenav from "examples/Sidenav";
import Footer from "examples/Footer";
import { Outlet, useLocation } from "react-router-dom";
import brand from "assets/images/public-Logo.png";
import routes from "routes/routes";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { ErrorBoundary } from "react-error-boundary";
import { Grid } from "@mui/material";
import { SoftButton } from "components";
import PropTypes from "prop-types";
import { SoftTypography } from "components";
import "global.css"


const ErrorHandler = () => {
  const location = useLocation()
  const onRefresh = () => {
    location.reload();
  };

  return (
    <Grid container sx={{ height: "20vh" }}>
      <Grid
        xs={12}
        item
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
        justifyContent={"center"}
      >
        <SoftTypography>Something went wrong</SoftTypography>
        <SoftButton onClick={onRefresh}>Refresh Page</SoftButton>
      </Grid>
    </Grid>
  );
};

ErrorHandler.propTypes = {
  error: PropTypes.string,
};

const Main = () => {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  
  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={brand}
              brandName="Proactive Drilling"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          </>
        )}
        <ErrorBoundary fallbackRender={({ error }) => <ErrorHandler error={error} />}>
          <div className="content_h">
            <Outlet />
          </div>
        </ErrorBoundary>
        <Footer />
      </DashboardLayout>
    </>
  );
};

export default Main;
